/* eslint-disable @typescript-eslint/no-explicit-any */
export const chunk = (
  arr: any[],
  chunkSize = 1,
  cache: any[] = []
): any[][] => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

export const randomNum = (limit: number, offset = 0): number =>
  Math.random() * limit + offset;

export const roundTo = (num: number, multiple = 5): number =>
  Math.round(num / multiple) * multiple;

export const randomFromArr = (arr: unknown[]): unknown =>
  arr[Math.floor(arr.length * Math.random())];

export const mapRange = (
  num: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const secondsToTime = (input: number): string => {
  const minutes = Math.floor(input / 60);
  const seconds = Math.floor(input - minutes * 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};
