import React, { useMemo, useCallback } from "react";
import classNames from "classnames";

import { BlockProps, BlockStage } from "../common";
import { Circles } from "../../common/Circles";
import { Markdown } from "../../common/Markdown";
import { useStoryContext } from "../../Context/StoryContext";
import { StoryBlockCommon } from "../../../types/data";

import styles from "./Expert.module.scss";
import { AnimatePresence, motion } from "framer-motion";

export interface ExpertBlockContent {
  content: string;
  title?: string;
  attribution?: string;
}

export interface ExpertBlockProps
  extends BlockProps,
    ExpertBlockContent,
    StoryBlockCommon {}

const ExpertBlock: React.FC<ExpertBlockProps> = ({
  className,
  content,
  title,
  attribution,
  stage,
  index,
  active,
}) => {
  const { observeElement } = useStoryContext();

  const cls = classNames(styles.block, styles[`stage${stage}`], className);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <section className={cls} ref={onRef} data-id={index}>
      <div className={styles.bg}>
        <Circles
          circleKey="001"
          className={classNames(styles.bgCircle, styles.bgCircleOne)}
          appear={250}
          show={active}
          fill={stage !== BlockStage.One}
          stroke
        />
        <Circles
          circleKey="003"
          className={classNames(styles.bgCircle, styles.bgCircleTwo)}
          appear={250}
          show={active}
          stroke
        />
        <Circles
          circleKey="006"
          className={classNames(styles.bgCircle, styles.bgCircleThree)}
          appear={500}
          show={active}
          fill={stage === BlockStage.Three}
          stroke
        />
        <Circles
          circleKey="003"
          className={classNames(styles.bgCircle, styles.bgCircleFour)}
          appear={150}
          show={active}
          stroke
        />
        <Circles
          circleKey="004"
          className={classNames(styles.bgCircle, styles.bgCircleFive)}
          appear={500}
          show={active}
          fill={stage !== BlockStage.One}
          stroke
        />
      </div>
      <div className={styles.stick}>
        <div className={styles.wrapper}>
          <AnimatePresence>
            {active && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className={styles.content}
                key="content"
              >
                {title && <h2>{title}</h2>}
                <Markdown source={content} />
              </motion.div>
            )}
            {active && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.5 }}
                key="attribution"
                className={styles.attribution}
              >
                <Markdown source={attribution} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export { ExpertBlock };
