import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntersectionObserver } from "../../utils/hooks/intersectionObserver";
import { BlockStage } from "../Blocks/common";
import { getStage } from "../../utils/data";
import { SwipeNotifier } from "../common/SwipeNotifier";
import { Dimensions } from "../../utils/hooks";
import { HELP_KEY, useAppContext } from "./AppContext";

interface StoryContextProps {
  setObserverRoot: (el: HTMLElement) => void;
  observeElement: (el: HTMLElement, id?: any) => void;
  activeIndex: number;
  activeStage: BlockStage;
  atEnd: boolean;
  promptSwipe: boolean;
  setPromptSwipe: Dispatch<SetStateAction<boolean>>;
  audioActive: boolean;
  setAudioActive: Dispatch<SetStateAction<boolean>>;
  windowDimensions: Dimensions;
  moveScroll: (scrollBy: number) => void;
  lock: boolean;
  setLock: Dispatch<SetStateAction<boolean>>;
  help: boolean;
  setHelp: Dispatch<SetStateAction<boolean>>;
  slug: string;
  title: string;
  trackTarget?: string | number;
  setTrackTarget: Dispatch<SetStateAction<string | number | undefined>>;
}

interface StoryContainerProps {
  children: React.ReactNode;
  totalBlocks: number;
  trackTargetPos?: { hash: string; location: string };
  onTrackTargetUsed?: () => void;
  slug: string;
  title: string;
}

const StoryContext = createContext<StoryContextProps>({
  setObserverRoot: () => false,
  observeElement: () => false,
  activeIndex: -1,
  activeStage: BlockStage.One,
  atEnd: false,
  promptSwipe: true,
  setPromptSwipe: () => false,
  audioActive: true,
  setAudioActive: () => false,
  windowDimensions: { width: 0, height: 0 },
  moveScroll: () => false,
  lock: true,
  setLock: () => false,
  help: true,
  setHelp: () => false,
  slug: "",
  title: "",
  setTrackTarget: () => false,
});

const StoryContainer: React.FC<StoryContainerProps> = ({
  children,
  totalBlocks,
  trackTargetPos,
  onTrackTargetUsed,
  slug,
  title,
}) => {
  const {
    windowDimensions,
    setLastStory,
    setNavigationReversing,
    hasSeenHelp,
    setHasSeenHelp,
  } = useAppContext();
  const [lock, setLock] = useState(true);
  const [help, setHelp] = useState(!hasSeenHelp);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [promptSwipe, setPromptSwipe] = useState<boolean>(true);
  const [audioActive, setAudioActive] = useState<boolean>(false);
  const [trackTarget, setTrackTarget] = useState<string | number | undefined>(
    trackTargetPos?.location
  );

  const onIntersect = useCallback<IntersectionObserverCallback>(entries => {
    const latestShowing = entries
      .filter(e => e.isIntersecting)
      .map(entry => (entry.target as HTMLElement).dataset.id)
      .pop();

    if (latestShowing) {
      setActiveIndex(parseInt(latestShowing, 10));
    } else {
      const latestLeaving = entries
        .filter(e => !e.isIntersecting)
        .map(entry => (entry.target as HTMLElement).dataset.id);

      if (latestLeaving.length === 1) {
        setActiveIndex(parseInt(latestLeaving.pop() as string, 10) + 1);
      }
    }
  }, []);

  const activeStage = useMemo(() => {
    return getStage(activeIndex, totalBlocks);
  }, [activeIndex, totalBlocks]);

  const { setObserverRoot, observeElement } = useIntersectionObserver(
    onIntersect
  );

  const moveScroll = useCallback((scrollBy: number) => {
    setTrackTarget(scrollBy);
  }, []);

  useEffect(() => {
    setNavigationReversing(!(activeIndex > 0));
  }, [activeIndex, setNavigationReversing]);

  useEffect(() => {
    setLastStory(slug);
  }, [setLastStory, slug]);

  useEffect(() => {
    if (!help && !hasSeenHelp) {
      setHasSeenHelp(true);
    }
  }, [hasSeenHelp, help, setHasSeenHelp]);

  useEffect(() => {
    if (localStorage.getItem(HELP_KEY)) {
      setHelp(false);
    }
  }, []);

  useEffect(() => {
    setTrackTarget(trackTargetPos?.location);
    if (onTrackTargetUsed) onTrackTargetUsed();
  }, [trackTargetPos, onTrackTargetUsed]);

  const value = {
    setObserverRoot,
    observeElement,
    activeIndex,
    activeStage,
    atEnd: activeIndex >= totalBlocks,
    promptSwipe,
    setPromptSwipe,
    audioActive,
    setAudioActive,
    windowDimensions,
    moveScroll,
    lock,
    setLock,
    help,
    setHelp,
    slug,
    title,
    trackTarget,
    setTrackTarget,
  };

  return (
    <StoryContext.Provider value={value}>
      {children}

      <SwipeNotifier
        active={
          !lock && promptSwipe && !audioActive && !(activeIndex >= totalBlocks)
        }
      />
    </StoryContext.Provider>
  );
};

const useStoryContext = (): StoryContextProps => useContext(StoryContext);

export { StoryContainer, useStoryContext };
