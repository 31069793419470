import React from "react";

import { motion } from "framer-motion";
import { Button } from "../../common/Button";
import { useStoryContext } from "../../Context/StoryContext";
import * as Analytics from "../../../utils/analytics.js";
import styles from "./HelpButton.module.scss";

const HelpButton: React.FC = () => {
  const { setHelp } = useStoryContext();

  return (
    <motion.div
      className={styles.btn}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Button
        icon="question"
        size="normal"
        aria-label="Press for help"
        onClick={() => {
          setHelp(true);
          Analytics.event({
            action: "click",
            category: "Help Button",
          });
        }}
      />
    </motion.div>
  );
};

export { HelpButton };
