import React from "react";

import classNames from "classnames";

import styles from "./ConversationTemplateTwo.module.scss";
import {
  ConversationBlockCommon,
  ConversationCommon,
} from "../ConversationCommon";
import { Markdown } from "../../../common/Markdown";
import { Circles } from "../../../common/Circles";
import { BubbleElement } from "../BubbleElement";

interface ConversationTemplateTwoProps
  extends ConversationCommon,
    ConversationBlockCommon {}

const ConversationTemplateTwo: React.FC<ConversationTemplateTwoProps> = ({
  title,
  sentenceOne,
  sentenceTwo,
  className,
  children,
  stickClassName,
}) => {
  return (
    <div
      className={classNames(
        styles.outer,
        { [styles.noTitle]: !title },
        className
      )}
    >
      <div className={styles.hangLeft}>
        <Circles
          className={classNames(styles.decorative, styles.decorativeOne)}
          circleKey="006"
          background
          stroke
          appear={250}
        />
        <Circles
          className={classNames(styles.decorative, styles.decorativeTwo)}
          circleKey="003"
          background
          fill
          appear={500}
        />
        <Circles
          className={classNames(styles.decorative, styles.decorativeThree)}
          circleKey="001"
          background
          stroke
          appear={500}
        />
      </div>

      <div className={styles.hangRight}>
        <Circles
          className={classNames(styles.decorative, styles.decorativeOne)}
          circleKey="006"
          background
          stroke
          appear={250}
        />
        <Circles
          className={classNames(styles.decorative, styles.decorativeTwo)}
          circleKey="004"
          background
          stroke
          appear={500}
        />
      </div>

      <div className={classNames(styles.inner, stickClassName)}>
        <div className={styles.title}>{title || ""}</div>
        <BubbleElement
          className={classNames(styles.bubble, styles.bubbleOne)}
          elementClassName={styles.bubbleContent}
          circleProps={{
            circleKey: "001",
            className: styles.circle,
            background: true,
            stroke: true,
            appear: 1000,
          }}
          sentence={sentenceOne}
        />
        <BubbleElement
          className={classNames(styles.bubble, styles.bubbleTwo)}
          elementClassName={styles.bubbleContent}
          circleProps={{
            circleKey: "006",
            className: styles.circle,
            background: true,
            stroke: true,
            appear: 1500,
          }}
          sentence={sentenceTwo}
        />
      </div>
      {children}
    </div>
  );
};

export { ConversationTemplateTwo };
