import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { motion, Variants } from "framer-motion";

import { performantUpdate } from "../../../utils/dom";
import { useStoryContext } from "../../Context/StoryContext";
import styles from "./Track.module.scss";
import { useAppContext } from "../../Context/AppContext";

const SlideIn: Variants = {
  initial: {
    x: "150%",
  },
  enter: {
    x: 0,
    transition: {
      duration: 3,
    },
  },
  exit: {
    y: "-100%",
    transition: {
      duration: 2,
    },
  },
  fadeExit: {
    opacity: 0,
    transition: {
      duration: 2,
    },
  },
  reverseExit: {
    x: "200%",
    transition: {
      duration: 1,
    },
  },
};

interface TrackProps {
  leftSlot?: React.ReactNode;
  swipeNotifierDelay?: number;
}

const Track: React.FC<TrackProps> = ({
  children,
  leftSlot,
  swipeNotifierDelay = 10000,
}) => {
  const { navigationReversing } = useAppContext();
  const {
    setObserverRoot,
    activeStage,
    atEnd,
    setPromptSwipe,
    lock,
    setLock,
    trackTarget,
    setTrackTarget,
  } = useStoryContext();
  const currentPosition = useRef<number>(0);
  const idlePosition = useRef<number>(0);
  const containerRef = useRef<HTMLElement | null>(null);
  const clearRef = useRef<() => void>(() => false);
  const idleTimerRef = useRef<number>(-1);
  const [scrollLeft, setScrollLeft] = useState<number>();

  const onRef = useCallback(
    ref => {
      if (ref) {
        containerRef.current = ref;

        setObserverRoot(ref);

        const cb = performantUpdate<Event>(e => {
          clearTimeout(idleTimerRef.current);

          idleTimerRef.current = window.setTimeout(() => {
            setPromptSwipe(true);
            idlePosition.current = currentPosition.current;
          }, swipeNotifierDelay);

          if (e.target) {
            currentPosition.current = (e.target as HTMLElement).scrollLeft;

            if (Math.abs(currentPosition.current - idlePosition.current) > 50) {
              setPromptSwipe(false);
            }
          }
        });

        containerRef.current?.addEventListener("scroll", cb, {
          passive: true,
        });

        clearRef.current = () => {
          containerRef.current?.removeEventListener("scroll", cb);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setObserverRoot]
  );

  useEffect(() => {
    if (document.documentElement.classList.contains("windows")) {
      const cb = performantUpdate<WheelEvent>(e => {
        if (containerRef.current) {
          containerRef.current.scrollLeft +=
            e.deltaMode === 1 ? e.deltaY * 50 : e.deltaY;
        }
      });

      window.addEventListener("wheel", cb);

      return () => {
        window.removeEventListener("wheel", cb);
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      clearRef.current();
    };
  }, []);

  useEffect(() => {
    if (lock) {
      return;
    }

    if (typeof trackTarget === "string") {
      const el = document.querySelector(trackTarget);

      if (el) {
        setScrollLeft(el.getBoundingClientRect().left);
      }
    } else {
      setScrollLeft(trackTarget);
    }

    setTrackTarget(undefined);
  }, [trackTarget, lock, setTrackTarget]);

  useEffect(() => {
    if (!lock && scrollLeft !== undefined && containerRef.current) {
      // containerRef.current.scrollLeft = scrollLeft;
      containerRef.current.scrollBy({
        top: 0,
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  }, [scrollLeft, lock]);

  const getExit = useCallback(() => {
    if (navigationReversing) {
      return "reverseExit";
    }

    if (atEnd) {
      return "fadeExit";
    }

    return "exit";
  }, [atEnd, navigationReversing]);

  return (
    <div
      className={classNames(
        styles.outer,
        { [styles.lock]: lock },
        styles[activeStage]
      )}
    >
      <motion.div
        variants={SlideIn}
        initial="initial"
        animate="enter"
        exit={getExit()}
        className={styles.track}
        ref={onRef}
        onAnimationComplete={() => setLock(false)}
        id="track"
      >
        {leftSlot && <div className={styles.left}>{leftSlot}</div>}
        {children}
      </motion.div>
    </div>
  );
};

export { Track };
