import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import { saveAs } from "file-saver";

import styles from "./Conversation.module.scss";
import { Markdown } from "../../common/Markdown";
import * as Analytics from "../../../utils/analytics.js";

import { ConversationTemplateOne } from "./ConversationTemplateOne";
import { ConversationTemplateTwo } from "./ConversationTemplateTwo";
import { ConversationTemplateThree } from "./ConversationTemplateThree";
// import { ConversationTemplateFour } from "./ConversationTemplateFour";
import { ConversationCommon } from "./ConversationCommon";
import { useStoryContext } from "../../Context/StoryContext";
import { Button } from "../../common/Button";
import { Circles } from "../../common/Circles";

export interface ConversationBlockContent extends ConversationCommon {
  image?: {
    publicURL: string;
  };
  template?: number;
  index: number;
}

const ConversationBlock: React.FC<ConversationBlockContent> = ({
  intro,
  sentenceOne,
  sentenceTwo,
  sentenceThree,
  title,
  image,
  template = 1,
  index,
}) => {
  const { observeElement, title: storyTitle } = useStoryContext();

  const downloadImage = useMemo(() => {
    if (!image) {
      return false;
    }

    return image.publicURL;
  }, [image]);

  const TemplatedEl = useMemo(() => {
    switch (template) {
      // case 4:
      //   return ConversationTemplateFour;
      case 3:
        return ConversationTemplateThree;
      case 2:
        return ConversationTemplateTwo;
      case 1:
      default:
        return ConversationTemplateOne;
    }
  }, [template]);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <section data-id={index} className={styles.page} ref={onRef}>
      <div
        className={classNames(styles.left, {
          [styles.larger]: intro.length < 100,
        })}
      >
        <div className={styles.stick}>
          <div className={styles.introContent}>
            <Circles
              circleKey="001"
              className={styles.introContentCircle}
              stroke
            />
            <div className={styles.introContentText}>
              <Markdown source={intro} />
            </div>
          </div>
        </div>
      </div>
      <TemplatedEl
        sentenceOne={sentenceOne}
        sentenceTwo={sentenceTwo}
        sentenceThree={sentenceThree}
        intro={intro}
        title={title}
        className={styles.right}
        stickClassName={styles.rightStick}
      >
        {downloadImage && (
          <div className={styles.downloadWrapper}>
            <Button
              className={styles.download}
              icon="download"
              onClick={() => {
                Analytics.event({
                  action: "click",
                  category: "Save Tips",
                });

                saveAs(
                  downloadImage,
                  `${storyTitle.replace("@", "")} Conversation Starter.png`
                );
              }}
            >
              Save & Share Tips
            </Button>
          </div>
        )}
      </TemplatedEl>
    </section>
  );
};

export { ConversationBlock };
