import React from "react";

import classNames from "classnames";

import styles from "./ConversationTemplateThree.module.scss";
import {
  ConversationBlockCommon,
  ConversationCommon,
} from "../ConversationCommon";
import { Markdown } from "../../../common/Markdown";
import { Circles } from "../../../common/Circles";
import { BubbleElement } from "../BubbleElement";

interface ConversationTemplateThreeProps
  extends ConversationCommon,
    ConversationBlockCommon {}

const ConversationTemplateThree: React.FC<ConversationTemplateThreeProps> = ({
  title,
  sentenceOne,
  sentenceTwo,
  sentenceThree,
  className,
  children,
  stickClassName,
}) => {
  return (
    <div className={classNames(styles.outer, className)}>
      <div className={styles.hangLeft}>
        <Circles
          className={classNames(styles.decorative, styles.decorativeOne)}
          circleKey="006"
          background
          stroke
          appear={250}
        />
        <Circles
          className={classNames(styles.decorative, styles.decorativeTwo)}
          circleKey="003"
          background
          fill
          appear={500}
        />
        <Circles
          className={classNames(styles.decorative, styles.decorativeThree)}
          circleKey="001"
          background
          stroke
          appear={500}
        />
      </div>

      <div className={styles.hangRight}>
        <Circles
          className={classNames(styles.decorative, styles.decorativeOne)}
          circleKey="006"
          background
          stroke
          appear={250}
        />
        <Circles
          className={classNames(styles.decorative, styles.decorativeTwo)}
          circleKey="004"
          background
          stroke
          appear={500}
        />
      </div>

      <div className={classNames(styles.inner, stickClassName)}>
        <div className={styles.title}>{title || ""}</div>
        <BubbleElement
          className={classNames(styles.bubble, styles.bubbleOne)}
          circleProps={{
            circleKey: "001",
            background: true,
            stroke: true,
            className: styles.circle,
          }}
          sentence={sentenceOne}
          elementClassName={styles.bubbleContent}
        />

        <BubbleElement
          className={classNames(styles.bubble, styles.bubbleTwo)}
          circleProps={{
            circleKey: "006",
            background: true,
            stroke: true,
            className: styles.circle,
          }}
          sentence={sentenceTwo}
          elementClassName={styles.bubbleContent}
        />

        <BubbleElement
          className={classNames(styles.bubble, styles.bubbleThree)}
          circleProps={{
            circleKey: "001",
            background: true,
            stroke: true,
            className: styles.circle,
          }}
          sentence={sentenceThree || ""}
          elementClassName={styles.bubbleContent}
        />
      </div>
      {children}
    </div>
  );
};

export { ConversationTemplateThree };
