import React from "react";
import classNames from "classnames";

import { Circles } from "../../common/Circles";

import styles from "./DecorativeBlock.module.scss";

const DecorativeBlockOne: React.FC = () => {
  return (
    <>
      <Circles
        className={classNames(styles.oneCircle, styles.oneCircleOne)}
        stroke
        animate={false}
        appear
        circleKey="001"
      />
      <Circles
        className={classNames(styles.oneCircle, styles.oneCircleTwo)}
        stroke
        animate={false}
        appear={150}
        circleKey="004"
      />
      <Circles
        className={classNames(styles.oneCircle, styles.oneCircleThree)}
        stroke
        animate={false}
        appear={300}
        circleKey="003"
      />
    </>
  );
};

const DecorativeBlock: React.FC = () => {
  return (
    <div className={styles.block}>
      <DecorativeBlockOne />
    </div>
  );
};

export { DecorativeBlock };
