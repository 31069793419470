import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import { useSwipeable } from "react-swipeable";

import { Button } from "../../common/Button";
import { Markdown } from "../../common/Markdown";

import { useStoryContext } from "../../Context/StoryContext";
import { useAppContext } from "../../Context/AppContext";
import styles from "./HelpOverlay.module.scss";

import SlideImageOne from "./assets/01-welcome.png";
import SlideImageTwo from "./assets/02-swipe.png";
import SlideImageThree from "./assets/03-save.png";
import SlideImageFour from "./assets/04-logo.png";
import SlideImageFive from "./assets/05-downloads.png";

const SLIDES = (touch: boolean) => [
  {
    text: `Welcome to Life Support. This experience has been designed to help you have conversations with your loved ones about death and dying`,
    image: SlideImageOne,
  },
  {
    text: "To explore a topic in depth, swipe right",
    image: SlideImageTwo,
  },
  {
    text:
      "Each topic gives you some easy tips to start a conversation, which you can save and send to loved ones",
    image: SlideImageThree,
  },
  {
    text: touch
      ? "To find your tips, look inside your phone's download folder or photo album"
      : "To find your tips, check where you saved the file - often this is inside your downloads folder.",
    image: SlideImageFive,
  },
  {
    text:
      "Tap the Life Support button to return to the main home page. Scroll down on this page to explore more topics",
    image: SlideImageFour,
  },
];

const HelpOverlay: React.FC = () => {
  const { intro, hasSeenHelp, isTouch } = useAppContext();
  const { lock, help, setHelp } = useStoryContext();

  const slides = useMemo(() => {
    if (intro && !hasSeenHelp) {
      return [...SLIDES(isTouch)];
    }

    return [...SLIDES(isTouch).slice(1)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouch]);

  const [slideIndex, setSlideIndex] = useState(0);

  const onPrev = useCallback(() => {
    setSlideIndex(index => {
      return index > 0 ? index - 1 : index;
    });
  }, []);

  const onNext = useCallback(() => {
    setSlideIndex(index => {
      return index < slides.length - 1 ? index + 1 : index;
    });
  }, [slides]);

  const handlers = useSwipeable({
    onSwipedRight: () => onPrev(),
    onSwipedLeft: () => onNext(),
    trackMouse: true,
  });

  useEffect(() => {
    Modal.setAppElement(document.body);
  }, []);

  return (
    <Modal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={!lock && help}
      onRequestClose={() => setHelp(false)}
      closeTimeoutMS={500}
    >
      <Button
        className={styles.close}
        icon="x"
        size="normal"
        onClick={() => setHelp(false)}
      />
      <div
        className={styles.track}
        {...handlers}
        style={
          {
            "--slide-index": slideIndex,
            "--total-slides": slides.length,
          } as React.CSSProperties
        }
      >
        {slides.map(slide => (
          <div key={slide.text} className={styles.slide}>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${slide.image})` }}
            />
            <div className={styles.info}>
              <Markdown source={slide.text} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.controls}>
        <div className={styles.controlsLeft}>
          <Button
            className={classNames(styles.arrow, styles.arrowLeft)}
            onClick={onPrev}
            icon="swipe-arrow"
            disabled={slideIndex === 0}
          />
        </div>
        <div className={styles.controlsCenter}>
          <ul className={styles.dots}>
            {slides.map((slide, index) => (
              <li key={index}>
                <button
                  className={classNames({
                    [styles.active]: index === slideIndex,
                  })}
                  onClick={() => setSlideIndex(index)}
                >
                  {index}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.controlsRight}>
          <Button
            className={classNames(styles.arrow, styles.arrowRight)}
            onClick={onNext}
            icon="swipe-arrow"
            disabled={slideIndex === slides.length - 1}
          />
        </div>
      </div>
    </Modal>
  );
};

export { HelpOverlay };
