import React from "react";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { Circles, CirclesProps } from "../../common/Circles";
import { Markdown } from "../../common/Markdown";

interface BubbleElementProps {
  className?: string;
  elementClassName?: string;
  sentence: string;
  circleProps: CirclesProps;
}

const BubbleElement: React.FC<BubbleElementProps> = ({
  className,
  elementClassName,
  sentence,
  circleProps,
}) => {
  const [measureRef, bounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  return (
    <div ref={measureRef} className={className}>
      <Circles {...circleProps} />
      <div className={elementClassName} style={{ width: bounds.height }}>
        <Markdown source={sentence} />
      </div>
    </div>
  );
};

export { BubbleElement };
