export enum BlockStage {
  One = "one",
  Two = "two",
  Three = "three",
}

export type BlockPagination = {
  nextSlug?: string;
  prevSlug?: string;
};

export interface BlockProps {
  className?: string;
  stage?: BlockStage;
}
