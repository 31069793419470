import React, { useMemo, useCallback } from "react";
import classNames from "classnames";

import { BlockProps, BlockStage } from "../common";
import { Circles } from "../../common/Circles";
import { Markdown } from "../../common/Markdown";
import { useStoryContext } from "../../Context/StoryContext";
import { StoryBlockCommon } from "../../../types/data";

import styles from "./Fact.module.scss";
import { AnimatePresence, motion } from "framer-motion";

export interface FactBlockContent {
  content: string;
  title?: string;
  attribution?: string;
}

export interface FactBlockProps
  extends BlockProps,
    FactBlockContent,
    StoryBlockCommon {}

const FactBlock: React.FC<FactBlockProps> = ({
  className,
  content,
  attribution,
  title,
  stage,
  index,
  active,
}) => {
  const { observeElement } = useStoryContext();

  const backgroundProps = useMemo(() => {
    switch (stage) {
      case BlockStage.Two:
      case BlockStage.Three:
        return {
          fill: true,
        };
      case BlockStage.One:
      default:
        return {
          stroke: true,
        };
    }
  }, [stage]);

  const cls = classNames(styles.block, styles[`stage${stage}`], className);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <section className={cls} ref={onRef} data-id={index}>
      <div className={styles.bg}>
        <Circles
          circleKey="004"
          className={classNames(styles.bgCircle, styles.bgCircleOne)}
          appear={250}
          show={active}
          stroke
          fill={stage === BlockStage.Three}
        />
        <Circles
          circleKey="005"
          className={classNames(styles.bgCircle, styles.bgCircleTwo)}
          appear={250}
          show={active}
          stroke
        />
      </div>
      <div className={styles.stick}>
        <Circles
          circleKey="002"
          className={styles.circle}
          background
          {...backgroundProps}
          appear
          show={active}
        />
        <AnimatePresence>
          {active && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className={styles.content}
            >
              {title && <h2>{title}</h2>}
              <Markdown source={content} />
              {attribution && <Markdown source={attribution} />}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export { FactBlock };
