import React, { useMemo, useCallback, useState, useEffect } from "react";
import classNames from "classnames";
// import { useSwipeable } from "react-swipeable";

import { BlockProps, BlockStage } from "../common";
import { Markdown } from "../../common/Markdown";
// import { StoryCircles } from "./StoryCircles";

import { useStoryContext } from "../../Context/StoryContext";
import { StoryBlockCommon } from "../../../types/data";
import { chunk } from "../../../utils/utils";
import { useIntersectionObserver } from "../../../utils/hooks/intersectionObserver";
import { ReactComponent as SvgConnector } from "./assets/connector.svg";
import styles from "./Story.module.scss";
import { Circles } from "../../common/Circles";

export interface StoryBlockContent {
  title: string;
  content: string;
}

export interface StoryBlockProps
  extends BlockProps,
    StoryBlockContent,
    StoryBlockCommon {}

const StoryBlock: React.FC<StoryBlockProps> = ({
  title,
  content,
  stage,
  index,
  active,
}) => {
  const { observeElement } = useStoryContext();
  const [activeItems, setActiveItems] = useState<number[]>([]);

  const onIntersect = useCallback<IntersectionObserverCallback>(
    (entries, observer) => {
      entries.forEach(e => {
        const idx = parseInt(
          (e.target as HTMLDivElement).dataset.item || "",
          10
        );

        if (idx < 0 || isNaN(idx)) {
          return;
        }

        if (e.isIntersecting) {
          // observer.unobserve(e.target);
          setActiveItems(items => {
            return [...items, idx];
          });
        } else {
          setActiveItems(items => {
            const tmp = items.slice();
            tmp.splice(tmp.indexOf(idx), 1);
            return tmp;
          });
        }
      });
    },
    []
  );

  const {
    observeElement: observeItemElement,
    setObserverRoot,
  } = useIntersectionObserver(onIntersect, {
    threshold: [0.1],
  });

  const cls = classNames(styles.block, styles[`stage${stage}`], {
    // [styles.invert]: (index || 0) % 2 === 1,
  });

  const splits = useMemo(() => {
    if (!content) {
      return [[]];
    }

    return chunk(
      content.split("\n").filter(t => t.trim() !== ""),
      3
    );
  }, [content]);

  const totalParts = useMemo(() => {
    return splits.reduce((prev, curr) => {
      return prev + curr.length;
    }, 0);
  }, [splits]);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  const onItemRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeItemElement(ref, ref.dataset.item);
      }
    },
    [observeItemElement]
  );

  useEffect(() => {
    setObserverRoot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    "--total-steps": totalParts,
    "--grid-overflow": Math.floor(totalParts / 3),
    "--leftover-rows": totalParts % 3,
  } as React.CSSProperties;

  const currentPageIndex = Math.floor(Math.max(...activeItems) / 3);

  return (
    <section data-id={index} style={style} ref={onRef} className={cls}>
      <div
        className={classNames(styles.content, {
          [styles.overflow]: totalParts % 3 !== 0,
        })}
      >
        <div
          className={classNames(styles.bg, {
            [styles.slim]: splits.length === 1,
          })}
        >
          <Circles
            circleKey="003"
            stroke
            appear={250}
            className={classNames(styles.bgCircle, styles.bgCircleOne)}
          />
          <Circles
            circleKey="004"
            stroke
            fill={stage !== BlockStage.One}
            appear={250}
            className={classNames(styles.bgCircle, styles.bgCircleTwo)}
          />
          <Circles
            circleKey="006"
            stroke
            appear={250}
            className={classNames(styles.bgCircle, styles.bgCircleThree)}
          />
          {splits.length > 1 && (
            <>
              <Circles
                circleKey="001"
                stroke
                appear={250}
                className={classNames(styles.bgCircle, styles.bgCircleFour)}
              />
              <Circles
                circleKey="004"
                stroke
                appear={250}
                fill={stage !== BlockStage.One}
                className={classNames(styles.bgCircle, styles.bgCircleFive)}
              />
              <Circles
                circleKey="005"
                stroke
                appear={250}
                className={classNames(styles.bgCircle, styles.bgCircleSix)}
              />
            </>
          )}
        </div>

        <h2 className={styles.title}>{title}</h2>

        <div className={styles.gridWrapper}>
          {splits.map((items, gridIndex) => {
            return (
              <div key={`grid-${gridIndex}`} className={styles.grid}>
                {items.map((v, idx) => {
                  const totalIndex = idx + gridIndex * 3;
                  const cls = classNames(styles.row, {
                    [styles.active]: activeItems.includes(totalIndex),
                  });

                  return (
                    <div
                      data-item={totalIndex}
                      key={idx}
                      ref={onItemRef}
                      className={cls}
                    >
                      <div className={styles.item}>
                        <Markdown source={v} />
                      </div>
                    </div>
                  );
                })}

                {gridIndex < splits.length - 1 && (
                  <SvgConnector
                    className={classNames(styles.connector, {
                      [styles.active]: currentPageIndex > gridIndex,
                    })}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { StoryBlock };
