import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

import styles from "./ConversationHelp.module.scss";
import { Circles } from "../../common/Circles";
import { useStoryContext } from "../../Context/StoryContext";
import { AnimatePresence, motion } from "framer-motion";

interface ConversationHelpBlockProps {
  index: number;
}

const ConversationHelpBlock: React.FC<ConversationHelpBlockProps> = ({
  index,
}) => {
  const { observeElement, activeIndex } = useStoryContext();

  const active = useMemo(() => {
    return index <= activeIndex;
  }, [activeIndex, index]);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <section
      data-id={index}
      ref={onRef}
      className={classNames(styles.page, { [styles.active]: active })}
    >
      <Circles
        circleKey="001"
        stroke
        className={classNames(styles.circle, styles.circleOne)}
      />
      <Circles
        circleKey="006"
        stroke
        className={classNames(styles.circle, styles.circleTwo)}
      />
      <div className={styles.content}>
        <p>
          Having open and honest conversations can be the first step towards
          having a 'good' death.
        </p>
        <p>
          The earlier you start these the better, as talking can not only help
          prepare you for your own future death, but can also enable those
          around you to speak freely about their wishes.
        </p>
        <p>This means when the time comes, everyone can feel more prepared.</p>
      </div>
    </section>
  );
};

export { ConversationHelpBlock };
