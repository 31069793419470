import React, { useState, useCallback, useMemo, useEffect } from "react";
import classNames from "classnames";

import styles from "./ConversationIntro.module.scss";
import { Circles } from "../../common/Circles";
import { useStoryContext } from "../../Context/StoryContext";
import { AnimatePresence, motion, Variant, Variants } from "framer-motion";

const content: Variants = {
  initial: {
    opacity: 1,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.5,
    },
  },
};

const option: Variants = {
  initial: custom => {
    const obj: Variant = {
      x: "-12.5vh",
      y: "-12.5vh",
    };

    if (custom === "0") {
      obj.x = "12.5vh";
      obj.y = "12.5vh";
    }

    if (custom === "2") {
      obj.x = "-10vh";
      obj.y = "30vh";
    }

    if (custom === "3") {
      obj.x = "10vh";
      obj.y = "-30vh";
    }

    return {
      ...obj,
      transition: {
        duration: 0.5,
      },
    };
  },
  enter: {
    x: 0,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 100,
    },
  },
  exit: {
    y: "-50%",
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const appear: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

interface ConversationIntroBlockProps {
  index: number;
}

const ConversationIntroBlock: React.FC<ConversationIntroBlockProps> = ({
  index,
}) => {
  const { observeElement, activeIndex } = useStoryContext();

  const active = useMemo(() => {
    return index <= activeIndex;
  }, [activeIndex, index]);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <section data-id={index} ref={onRef} className={classNames(styles.page)}>
      <div className={styles.bg}>
        <Circles
          className={styles.bgCircle}
          circleKey="squared"
          background
          stretch
          fill
          appear={1000}
          show
          animate={false}
        />
      </div>

      <AnimatePresence exitBeforeEnter>
        <motion.div
          variants={content}
          initial="initial"
          animate={active ? "enter" : "initial"}
          exit="exit"
          className={styles.content}
          key="intro"
        >
          <motion.div
            variants={option}
            custom={"0"}
            className={classNames(styles.circle, styles.one)}
          >
            <Circles className={styles.circleActual} circleKey="003" fill />
          </motion.div>
          <motion.div
            variants={option}
            custom={"1"}
            className={classNames(styles.circle, styles.two)}
          >
            <Circles className={styles.circleActual} circleKey="001" fill />
          </motion.div>
          <motion.div
            variants={option}
            custom={"2"}
            className={classNames(styles.circle, styles.three)}
          >
            <Circles className={styles.circleActual} circleKey="006" stroke />
          </motion.div>
          <motion.div
            variants={option}
            custom={"3"}
            className={classNames(styles.circle, styles.four)}
          >
            <Circles className={styles.circleActual} circleKey="005" stroke />
          </motion.div>
          <motion.p variants={appear}>
            Why it's important to have conversations about death and dying
          </motion.p>
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export { ConversationIntroBlock };
