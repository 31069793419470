import { BlockStage } from "../components/Blocks/common";
export const getStage = (idx: number, arrLength: number): BlockStage => {
  if (idx < arrLength / 3) {
    return BlockStage.One;
  }

  if (idx < (arrLength / 3) * 2) {
    return BlockStage.Two;
  }

  return BlockStage.Three;
};
