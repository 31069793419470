import React, { useCallback } from "react";
import classNames from "classnames";
import { useSwipeable } from "react-swipeable";
import { navigate } from "gatsby";

import { BlockProps } from "../common";
import { Circles } from "../../common/Circles";
import { Markdown } from "../../common/Markdown";
import { useStoryContext } from "../../Context/StoryContext";
import { StoryBlockCommon } from "../../../types/data";

import styles from "./Intro.module.scss";

export interface IntroBlockContent {
  content: string;
  title?: string;
}

export interface IntroBlockProps
  extends BlockProps,
    IntroBlockContent,
    StoryBlockCommon {}

const IntroBlock: React.FC<IntroBlockProps> = ({
  className,
  content,
  title,
  stage,
  index,
  active,
}) => {
  const { observeElement } = useStoryContext();

  const handlers = useSwipeable({
    delta: 100,
    onSwipedRight: () => {
      navigate("/");
    },
  });

  const cls = classNames(styles.block, styles[`stage${stage}`], className);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <section className={cls} ref={onRef} data-id={index}>
      <div className={styles.bg}>
        <Circles
          appear={2000}
          circleKey="001"
          stroke
          className={classNames(styles.bgCircle, styles.bgCircleOne)}
        />
        <Circles
          appear={2250}
          circleKey="001"
          stroke
          className={classNames(styles.bgCircle, styles.bgCircleFour)}
        />
        <Circles
          appear={1500}
          circleKey="006"
          stroke
          className={classNames(styles.bgCircle, styles.bgCircleTwo)}
        />
        <Circles
          appear={1750}
          circleKey="006"
          stroke
          className={classNames(styles.bgCircle, styles.bgCircleThree)}
        />
      </div>
      <div {...handlers} className={styles.stick}>
        <div className={styles.grid}>
          <div className={styles.title}>
            <Circles
              circleKey="006"
              className={styles.circle}
              stroke
              appear
              show={active}
            />
            <div className={styles.inner}>
              <h2>{title}</h2>
            </div>
          </div>

          <div className={styles.content}>
            <Circles
              circleKey="001"
              className={styles.circle}
              stroke
              appear
              show={active}
              offset="1.5s"
            />
            <div className={styles.inner}>
              <Markdown source={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { IntroBlock };
