import React, { useCallback, useEffect, useState } from "react";

import { graphql, PageProps } from "gatsby";

import { StoryContentNode } from "../types/data";
import { SEO } from "../components/common/seo";
import { StoryContainer } from "../components/Context/StoryContext";
import { ConversationIntroBlock } from "../components/Blocks/ConversationIntro";
import { Track } from "../components/Layout/Track";
import { BlockSwitch } from "../components/Blocks/BlockSwitch";
import { DecorativeBlock } from "../components/Blocks/DecorativeBlock";
import { getStage } from "../utils/data";
import { NextStepsBlock } from "../components/Blocks/NextSteps";
import { ConversationBlock } from "../components/Blocks/Conversation";
import { HelpOverlay } from "../components/Layout/HelpOverlay";
import { HelpButton } from "../components/Layout/HelpButton";
import { ConversationHelpBlock } from "../components/Blocks/ConversationHelp";

interface StoryTemplateProps {
  storyContent: StoryContentNode;
}

interface StoryTemplateContext {
  slug: string;
}

const getAudioLocation = (hash?: string) =>
  hash
    ? {
        hash,
        location: `#audio-${hash.substring(1)}`,
      }
    : undefined;

const StoryTemplate: React.FC<PageProps<
  StoryTemplateProps,
  StoryTemplateContext
>> = ({ data, pageContext, location }) => {
  const [targetHash, setTargetHash] = useState<
    { hash: string; location: string } | undefined
  >(getAudioLocation(location.hash));

  useEffect(() => {
    if (location.hash) {
      setTargetHash(getAudioLocation(location.hash));
      window.location.hash = "";
    }
  }, [location.hash, targetHash]);

  const onTrackTargetUsed = useCallback(() => {
    setTargetHash(undefined);
  }, []);

  return (
    <>
      <StoryContainer
        totalBlocks={data.storyContent.content.length}
        trackTargetPos={targetHash}
        onTrackTargetUsed={onTrackTargetUsed}
        slug={`/story/${pageContext.slug}`}
        title={data.storyContent.title
          .split("\n")
          .join(" ")
          .replace("@", "")
          .split("  ")
          .join(" ")}
      >
        <SEO title={data.storyContent.name} />

        <Track leftSlot={<DecorativeBlock />}>
          {data.storyContent.content.map((block, idx, arr) => (
            <BlockSwitch
              key={idx}
              {...block}
              stage={getStage(idx, arr.length - 1)}
              index={idx}
            />
          ))}
          <ConversationIntroBlock index={data.storyContent.content.length} />

          <ConversationHelpBlock index={data.storyContent.content.length + 1} />

          <ConversationBlock
            index={data.storyContent.content.length + 2}
            {...data.storyContent.conversation}
          />

          <NextStepsBlock
            index={data.storyContent.content.length + 3}
            steps={data.storyContent.nextSteps}
          />

          <HelpOverlay />
        </Track>
        <HelpButton />
      </StoryContainer>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default StoryTemplate;

export const query = graphql`
  query($slug: String!) {
    storyContent(slug: { eq: $slug }) {
      conversation {
        intro
        title
        image {
          publicURL
        }
        sentenceOne
        sentenceTwo
        sentenceThree
        template
      }
      nextSteps {
        content
        title
        to
        type
      }
      content {
        audioContent {
          audioFile {
            id
            publicURL
            fields {
              duration
            }
          }
          content
          info
        }
        storyContent {
          content
          title
        }
        factContent {
          content
          title
          attribution
        }
        type
        slug
        expertContent {
          attribution
          content
          title
        }
        introContent {
          content
          title
        }
      }
      fullSlug
      name
      slug
      title
      circle
    }
  }
`;
