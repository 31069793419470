import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

import { BlockProps, BlockStage } from "../common";
import { Player } from "../../common/Player";
import { Button } from "../../common/Button";
import { Circles } from "../../common/Circles";
import { Markdown } from "../../common/Markdown";

import { StoryBlockCommon } from "../../../types/data";
import { useAppContext } from "../../Context/AppContext";
import { useStoryContext } from "../../Context/StoryContext";
import styles from "./Audio.module.scss";
import { motion } from "framer-motion";
import { Tooltip } from "../../common/Tooltip";
import * as Analytics from "../../../utils/analytics.js";

export interface AudioBlockContent extends StoryBlockCommon {
  content: string;
  audioFile: null | {
    id: string;
    publicURL: string;
    fields: {
      duration: number | null;
    };
  };
  info: string;
}

export interface AudioBlockProps extends BlockProps, AudioBlockContent {}

const AudioBlock: React.FC<AudioBlockProps> = ({
  content,
  info,
  audioFile,
  className,
  stage = BlockStage.One,
  index,
  active,
  render,
}) => {
  const { isSavedAudio, toggleAudioFile } = useAppContext();
  const {
    observeElement,
    activeIndex,
    setAudioActive,
    help,
  } = useStoryContext();

  const cls = classNames(styles.block, styles[`stage${stage}`], className);

  const backgroundProps = useMemo(() => {
    switch (stage) {
      case BlockStage.Three:
        return {
          fill: true,
          stroke: true,
        };
      case BlockStage.Two:
        return {
          fill: true,
          stroke: true,
        };
      case BlockStage.One:
      default:
        return {
          fill: true,
          stroke: true,
        };
    }
  }, [stage]);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  return (
    <div
      id={audioFile ? `audio-${audioFile.id}` : undefined}
      data-id={index}
      className={cls}
      ref={onRef}
    >
      <>
        <Circles
          circleKey="003"
          stroke
          fill={stage === BlockStage.Three}
          appear={500}
          show={active}
          className={classNames(styles.background, styles.backgroundOne)}
        />
        <Circles
          circleKey="004"
          stroke
          fill={stage !== BlockStage.One}
          appear={1000}
          show={active}
          className={classNames(styles.background, styles.backgroundTwo)}
        />
        <Circles
          circleKey="005"
          stroke
          appear={1500}
          show={active}
          className={classNames(styles.background, styles.backgroundThree)}
        />
      </>

      {/* {render && ( */}
      <>
        <div className={styles.grid}>
          <h2 className={styles.title}>Listen to an Expert</h2>
          <div className={styles.media}>
            <Circles
              className={styles.circle}
              circleKey="001"
              background
              appear={250}
              show={active}
              {...backgroundProps}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={active ? { opacity: 1 } : { opacity: 0 }}
              transition={{
                duration: 1,
                delay: 1,
              }}
              className={styles.content}
            >
              <Markdown source={info} />
              {audioFile && (
                <>
                  <Player
                    className={styles.player}
                    // active={Math.abs((index || -1) - activeIndex) < 2 && !help}
                    active={Math.abs((index || 0) - activeIndex) <= 1 && !help}
                    src={audioFile.publicURL}
                    time={audioFile.fields.duration || undefined}
                    onPlayingChange={playing => setAudioActive(playing)}
                  />

                  {/* <Tooltip
                      tip="Tap the bookmark to save audio extracts for later. Your saved audio is stored in the main menu."
                      align="center"
                      persistentId="bookmark-button"
                      persists
                    > */}
                  <Button
                    className={styles.bookmark}
                    buttonType="accent"
                    icon={
                      isSavedAudio(audioFile.id) ? "bookmark-fill" : "bookmark"
                    }
                    size="xlarge"
                    onClick={() => {
                      Analytics.event({
                        action: "click",
                        category: "Audio Bookmark",
                      });
                      toggleAudioFile(audioFile.id);
                    }}
                  />
                  {/* </Tooltip> */}
                </>
              )}
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={active ? { opacity: 1 } : { opacity: 0 }}
            transition={{
              duration: 1,
              delay: 1.5,
            }}
            className={styles.info}
          >
            <Markdown source={content} />
          </motion.div>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export { AudioBlock };
