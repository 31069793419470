import { AudioBlockContent } from "../components/Blocks/Audio";
import { BlockStage } from "../components/Blocks/common";
import { ExpertBlockContent } from "../components/Blocks/Expert";
import { FactBlockContent } from "../components/Blocks/Fact";
import { IntroBlockContent } from "../components/Blocks/Intro";
import { NextStepType } from "../components/Blocks/NextSteps";
import { StoryBlockContent } from "../components/Blocks/Story";
import { CirclesMap } from "../components/common/Circles";

export enum StoryContentType {
  Fact = "fact",
  Story = "story",
  Audio = "audio",
  Intro = "intro",
  Expert = "expert",
}

export interface StoryBlockCommon {
  stage?: BlockStage;
  index?: number;
  active?: boolean;
  render?: boolean;
}

export interface AudioBlockType extends StoryBlockCommon {
  type: StoryContentType.Audio;
  content: AudioBlockContent;
}

export interface StoryBlockType extends StoryBlockCommon {
  type: StoryContentType.Story;
  content: StoryBlockContent;
}

export interface FactBlockType extends StoryBlockCommon {
  type: StoryContentType.Fact;
  content: FactBlockContent;
}

export interface IntroBlockType extends StoryBlockCommon {
  type: StoryContentType.Intro;
  content: IntroBlockContent;
}

export interface ExpertBlockType extends StoryBlockCommon {
  type: StoryContentType.Expert;
  content: ExpertBlockContent;
}

export type StoryContentNodeContent =
  | AudioBlockType
  | StoryBlockType
  | FactBlockType;

export interface StoryContentNode {
  content: StoryContentNodeContent[];
  fullSlug: string;
  name: string;
  slug: string;
  title: string;
  circle: keyof typeof CirclesMap;
  conversation: {
    intro: string;
    image?: {
      publicURL: string;
    };
    sentenceOne: string;
    sentenceTwo: string;
    template: number;
  };
  nextSteps: Array<{
    to?: string;
    title: string;
    content: string;
    type: NextStepType;
  }>;
}

export interface AudioItem {
  snippet?: string;
  story: string;
  targetId: string;
  attribution?: string;
}
