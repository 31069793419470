import React, { useRef, useState, useEffect, useCallback } from "react";
import classNames from "classnames";

import styles from "./Player.module.scss";
import { ReactComponent as Progress } from "./assets/progress.svg";
import { Button } from "../Button";
import { secondsToTime } from "../../../utils/utils";
import * as Analytics from "../../../utils/analytics.js";

interface PlayerProps {
  src: string;
  className?: string;
  time?: number;
  active?: boolean;
  onPlayingChange?: (playing: boolean) => void;
}

const Player: React.FC<PlayerProps> = ({
  src,
  className,
  time,
  active,
  onPlayingChange,
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(time || 0);

  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlay = useCallback(() => {
    Analytics.event({
      action: "click",
      category: "Player",
      value: playing ? "Pause" : "Play",
    });

    if (playing) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }

    setPlaying(!playing);
  }, [playing]);

  const restartAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setProgress(0);
    }
  }, []);

  const onAudioUpdate = useCallback(
    (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
      const target = e.target as HTMLAudioElement;
      setProgress(target.currentTime / target.duration);
      setCurrentTime(target.duration - target.currentTime);
    },
    []
  );

  useEffect(() => {
    if (onPlayingChange) {
      onPlayingChange(playing);
    }
  }, [onPlayingChange, playing]);

  useEffect(() => {
    if (!active) {
      setPlaying(false);
      audioRef.current?.pause();
    }
  }, [active]);

  const onEnded = useCallback(() => {
    setPlaying(false);

    Analytics.event({
      action: "audio",
      label: "Player",
      value: "Ended",
    });
  }, []);

  const cls = classNames(styles.wrapper, className);

  return (
    <>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={onAudioUpdate}
        onEnded={onEnded}
      />
      <div className={cls}>
        <div className={styles.top}>
          <Button
            className={styles.btn}
            buttonType="accentFill"
            icon={playing ? "pause" : "play"}
            onClick={togglePlay}
            size="large"
            aria-label={playing ? "Pause Audio" : "Play Audio"}
          />
          <div className={styles.progress}>
            <Progress />
            <Progress
              style={{ strokeDashoffset: (1 - progress) * 1030 }}
              className={styles.overlay}
            />
          </div>

          <div className={styles.restart}>
            <Button size="normal" icon="replay" onClick={restartAudio} />
          </div>
        </div>

        <div className={styles.duration}>{secondsToTime(currentTime)}</div>
      </div>
    </>
  );
};

export { Player };
