import React, { useMemo, useCallback, useState } from "react";
import classNames from "classnames";

import { BubbleContent } from "../../common/BubbleContent";
import { CirclesMap } from "../../common/Circles";

import styles from "./NextSteps.module.scss";
import { motion, Variants } from "framer-motion";
import { StoryListItem } from "../../Layout/StoriesList/StoryListItem";
import { useStoryContext } from "../../Context/StoryContext";

export enum NextStepType {
  Text = "text",
  External = "external_link",
  Internal = "internal_link",
}

export interface NextStepItem {
  to?: string;
  title: string;
  content: string;
  type: NextStepType;
}

interface NextStepsBlockProps {
  steps: NextStepItem[];
  index: number;
}

const container: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
      delayChildren: 0.25,
      staggerChildren: 0.5,
    },
  },
  // exit: {
  //   opacity: 0,
  //   transition: {
  //     duration: 1,
  //     staggerChildren: 0.5,
  //   },
  // },
};

const appear: Variants = {
  initial: {
    y: "-20px",
    opacity: 0,
  },
  enter: custom => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      delay: custom === 1 ? 0.5 : 0,
    },
  }),
  exit: {
    y: 0,
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const NextStepsBlock: React.FC<NextStepsBlockProps> = ({ steps, index }) => {
  const { activeIndex, observeElement } = useStoryContext();

  const stepsSlice = useMemo(() => {
    return steps.slice(0, 2);
  }, [steps]);

  const active = useMemo(() => {
    return index <= activeIndex;
  }, [activeIndex, index]);

  const [activeLinks, setActiveLinks] = useState(active);

  const onRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        observeElement(ref);
      }
    },
    [observeElement]
  );

  // const gridStyles = {
  //   "--item-width": steps.length === 1 ? "100%" : "75%",
  // } as React.CSSProperties;

  return (
    <section className={styles.page} ref={onRef} data-id={index}>
      <motion.div
        variants={container}
        initial="initial"
        animate={active ? "enter" : "initial"}
        exit="exit"
        className={styles.grid}
        onAnimationComplete={() => setActiveLinks(active)}
        style={{ "--total-items": stepsSlice.length } as React.CSSProperties}
      >
        {stepsSlice.map((step, idx) => {
          if (step.type === NextStepType.Internal) {
            return (
              <motion.div
                variants={appear}
                className={styles.item}
                key={idx}
                custom={idx}
              >
                <h2 className={styles.title}>
                  {idx === 1 && "Or "}Explore the Next Story:
                </h2>
                <StoryListItem
                  onRef={() => false}
                  slug={activeLinks ? `/story/${step.to}` || "/" : undefined}
                  index={0}
                  title={step.title}
                  variant={2}
                  active
                  className={classNames(styles.content, styles.story)}
                  smaller
                />
              </motion.div>
            );
          }

          return (
            <motion.div
              variants={appear}
              className={styles.item}
              custom={idx}
              key={idx}
            >
              <h2 className={styles.title}>{idx === 1 && "Or "}Learn About:</h2>
              <BubbleContent
                to={activeLinks ? step.to : undefined}
                guardLink
                circle={
                  (idx + 1)
                    .toString()
                    .padStart(3, "0") as keyof typeof CirclesMap
                }
                title={step.content}
                // content={step.content}
                // icon={step.to ? "external" : "info"}
                variant={0}
                className={styles.content}
              />
            </motion.div>
          );
        })}
        {/* {stepsSlice.length >= 2 && <span className={styles.divide}>Or...</span>} */}
      </motion.div>
    </section>
  );
};

export { NextStepsBlock };
