import React, { useCallback } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Icon } from "../Icon";
import { ReactComponent as SwipeBg } from "./assets/swipe-bg.svg";
import * as Analytics from "../../../utils/analytics.js";

import styles from "./SwipeNotifier.module.scss";
import { useAppContext } from "../../Context/AppContext";
import { useStoryContext } from "../../Context/StoryContext";

interface SwipeNotifierProps {
  active?: boolean;
  className?: string;
}

const SwipeNotifier: React.FC<SwipeNotifierProps> = ({ active, className }) => {
  const { isTouch } = useAppContext();
  const { moveScroll, activeIndex } = useStoryContext();

  const onClick = useCallback(() => {
    Analytics.event({
      action: "click",
      category: "Swipe Prompt",
    });

    const el = document.querySelector(`[data-id="${activeIndex}"]`);

    if (el && el.nextElementSibling) {
      const { left } = el.nextElementSibling.getBoundingClientRect();

      moveScroll(left);
    } else {
      moveScroll(window.innerWidth);
    }
  }, [activeIndex, moveScroll]);

  return (
    <AnimatePresence>
      {active && (
        <motion.div
          initial={{ opacity: 0, x: "-30%" }}
          animate={{ opacity: 1, x: "-50%" }}
          exit={{ opacity: 0, x: "-70%" }}
          transition={{ duration: 1 }}
          className={classNames(styles.swipe, className)}
          role="button"
          onClick={onClick}
        >
          <SwipeBg className={styles.bg} />
          <span className={styles.text}>{isTouch ? "Swipe" : "Scroll"}</span>
          <div className={styles.arrows}>
            <Icon className={styles.icon} name="swipe-arrow" />
            <Icon className={styles.icon} name="swipe-arrow" />
            <Icon className={styles.icon} name="swipe-arrow" />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { SwipeNotifier };
