import React from "react";
import { StoryContentType } from "../../../types/data";

import { AudioBlock, AudioBlockProps } from "../../Blocks/Audio";
import { FactBlock, FactBlockProps } from "../../Blocks/Fact";
import { StoryBlock, StoryBlockProps } from "../../Blocks/Story";
import { StoryBlockCommon } from "../../../types/data";
import { useStoryContext } from "../../Context/StoryContext";
import { ExpertBlock, ExpertBlockProps } from "../Expert";
import { IntroBlock, IntroBlockProps } from "../Intro";

interface BlockSwitchProps extends StoryBlockCommon {
  type: StoryContentType;
  audioContent?: AudioBlockProps;
  factContent?: FactBlockProps;
  storyContent?: StoryBlockProps;
  expertContent?: ExpertBlockProps;
  introContent?: IntroBlockProps;
}

const BlockSwitch: React.FC<BlockSwitchProps> = ({
  type,
  audioContent,
  factContent,
  storyContent,
  expertContent,
  introContent,
  stage,
  index = -1,
}) => {
  const { activeIndex } = useStoryContext();

  if (type === StoryContentType.Audio && audioContent) {
    return (
      <AudioBlock
        stage={stage}
        active={index <= activeIndex}
        render={Math.abs(index - activeIndex) < 2}
        index={index}
        {...audioContent}
      />
    );
  }
  if (type === StoryContentType.Fact && factContent) {
    return (
      <FactBlock
        stage={stage}
        active={index <= activeIndex}
        render={Math.abs(index - activeIndex) < 2}
        index={index}
        {...factContent}
      />
    );
  }
  if (type === StoryContentType.Story && storyContent) {
    return (
      <StoryBlock
        stage={stage}
        active={index <= activeIndex}
        render={Math.abs(index - activeIndex) < 2}
        index={index}
        {...storyContent}
      />
    );
  }
  if (type === StoryContentType.Expert && expertContent) {
    return (
      <ExpertBlock
        stage={stage}
        active={index <= activeIndex}
        render={Math.abs(index - activeIndex) < 2}
        index={index}
        {...expertContent}
      />
    );
  }
  if (type === StoryContentType.Intro && introContent) {
    return (
      <IntroBlock
        stage={stage}
        active={index <= activeIndex}
        render={Math.abs(index - activeIndex) < 2}
        index={index}
        {...introContent}
      />
    );
  }
  return null;
};

export { BlockSwitch };
